import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";

const Address = (props) => {
  const [addresses, setAddresses] = useState([]);
  const [loader, setLoader] = useState("");
  const [address, setAddress] = useState({});
  const [states, setStates] = useState([
    {
      ID: "AK",
      Name: "Alaska",
    },
    {
      ID: "AL",
      Name: "Alabama",
    },
    {
      ID: "AR",
      Name: "Arkansas",
    },
    {
      ID: "AZ",
      Name: "Arizona",
    },
    {
      ID: "CA",
      Name: "California",
    },
    {
      ID: "CO",
      Name: "Colorado",
    },
    {
      ID: "CT",
      Name: "Connecticut",
    },
    {
      ID: "DC",
      Name: "District Of Columbia",
    },
    {
      ID: "DE",
      Name: "Delaware",
    },
    {
      ID: "FL",
      Name: "Florida",
    },
    {
      ID: "GA",
      Name: "Georgia",
    },
    {
      ID: "HI",
      Name: "Hawaii",
    },
    {
      ID: "IA",
      Name: "Iowa",
    },
    {
      ID: "ID",
      Name: "Idaho",
    },
    {
      ID: "IL",
      Name: "Illinois",
    },
    {
      ID: "IN",
      Name: "Indiana",
    },
    {
      ID: "KS",
      Name: "Kansas",
    },
    {
      ID: "KY",
      Name: "Kentucky",
    },
    {
      ID: "LA",
      Name: "Louisiana",
    },
    {
      ID: "MA",
      Name: "Massachusetts",
    },
    {
      ID: "MD",
      Name: "Maryland",
    },
    {
      ID: "ME",
      Name: "Maine",
    },
    {
      ID: "MI",
      Name: "Michigan",
    },
    {
      ID: "MN",
      Name: "Minnesota",
    },
    {
      ID: "MO",
      Name: "Missouri",
    },
    {
      ID: "MS",
      Name: "Mississippi",
    },
    {
      ID: "MT",
      Name: "Montana",
    },
    {
      ID: "NC",
      Name: "North Carolina",
    },
    {
      ID: "ND",
      Name: "North Dakota",
    },
    {
      ID: "NE",
      Name: "Nebraska",
    },
    {
      ID: "NH",
      Name: "New Hampshire",
    },
    {
      ID: "NJ",
      Name: "New Jersey",
    },
    {
      ID: "NM",
      Name: "New Mexico",
    },
    {
      ID: "NV",
      Name: "Nevada",
    },
    {
      ID: "NY",
      Name: "New York",
    },
    {
      ID: "OH",
      Name: "Ohio",
    },
    {
      ID: "OK",
      Name: "Oklahoma",
    },
    {
      ID: "OR",
      Name: "Oregon",
    },
    {
      ID: "PA",
      Name: "Pennsylvania",
    },
    {
      ID: "PR",
      Name: "Puerto Rico",
    },
    {
      ID: "RI",
      Name: "Rhode Island",
    },
    {
      ID: "SC",
      Name: "South Carolina",
    },
    {
      ID: "SD",
      Name: "South Dakota",
    },
    {
      ID: "TN",
      Name: "Tennessee",
    },
    {
      ID: "TX",
      Name: "Texas",
    },
    {
      ID: "UT",
      Name: "Utah",
    },
    {
      ID: "VA",
      Name: "Virginia",
    },
    {
      ID: "VT",
      Name: "Vermont",
    },
    {
      ID: "WA",
      Name: "Washington",
    },
    {
      ID: "WI",
      Name: "Wisconsin",
    },
    {
      ID: "WV",
      Name: "West Virginia",
    },
    {
      ID: "WY",
      Name: "Wyoming",
    },
  ]);
  const getAddresses = async () => {
    await axios
      .get(server + "/api/v1/address/readAll", config)
      .then((res) => {
        setAddresses(res.data.addresses);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createAddress = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const params = {
      name: e.target.name.value,
      street: e.target.street.value,
      city: e.target.city.value,
      state: e.target.state.value,
      zip: e.target.zip.value,
      street2: e.target.street2.value,
      country: "US",
    };

    await axios
      .post(server + "/api/v1/address/create", params, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        getAddresses();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  const updateAddress = async (e) => {
    e.preventDefault();

    setLoader(<Loader />);

    const params = {
      name: address.name,
      street: address.street,
      city: address.city,
      state: address.state,
      zip: address.zip,
      street2: address.street2,
      country: "US",
    };

    await axios
      .put(server + "/api/v1/address/update/" + address._id, params, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        getAddresses();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  const deleteAddress = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    await axios
      .delete(server + "/api/v1/address/delete/" + address._id, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        getAddresses();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getAddresses();
  }, []);

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex justify-content-end mb-2">
                    <button className="btn btn-sm btn-primary px-2 py-1" data-target="#add" data-toggle="modal">
                      Create Address
                    </button>
                  </div>
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Country</th>
                          <th scope="col">name</th>
                          <th scope="col">Street</th>
                          <th scope="col">Street2</th>
                          <th scope="col">city</th>
                          <th scope="col">state</th>
                          <th scope="col">zip</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {addresses.map((address, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>United States</td>
                            <td>{address.name}</td>
                            <td>{address.street}</td>
                            <td>{address.street2}</td>
                            <td>{address.city}</td>
                            <td>{address.state}</td>
                            <td>{address.zip}</td>
                            <td>
                              <button
                                className="btn btn-sm text-info  px-2 py-1"
                                data-target="#update"
                                data-toggle="modal"
                                onClick={() => setAddress(address)}
                              >
                                <em className="icon ni ni-edit" />
                              </button>
                              <button
                                className="btn btn-sm text-danger px-2 py-1"
                                data-target="#delete"
                                data-toggle="modal"
                                onClick={() => setAddress(address)}
                              >
                                <em className="icon ni ni-trash" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* create address modal */}
      <div id="add" className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Add Address
              </h5>
              <Link to="#" className="close" data-dismiss="modal" aria-label="Close" id="close">
                <em className="icon ni ni-cross" />
              </Link>
            </div>
            <div className="modal-body">
              <form onSubmit={createAddress}>
                <div className="form-group mb-3">
                  <label htmlFor="name">Name</label>
                  <input type="text" required className="form-control" id="name" placeholder="Enter Name" name="name" />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="name">Street</label>
                  <input type="text" required className="form-control" id="street" placeholder="Enter Street" name="street" />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="name">Street 2 (optional)</label>
                  <input type="text" className="form-control" id="street2" placeholder="Enter Street 2" name="street2" />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="name">ZIP Code</label>
                  <input type="number" required className="form-control" id="zip" placeholder="Enter zip" name="zip" />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="name">City</label>
                  <input type="text" required className="form-control" id="city" placeholder="Enter City" name="city" />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="name">State</label>
                  <select name="state" id="state" className="form-control" required>
                    <option value="">Select state</option>
                    {states.map((state, index) => (
                      <option key={index} value={state.ID}>
                        {state.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="exampleInputEmail1">Country</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter Country"
                    name="country"
                    value="United States"
                    disabled
                  />
                </div>
                <div className="d-flex mt-2 justify-content-end ">
                  <button type="button" className="btn btn-white me-3" data-bs-dismiss="modal">
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Create {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* update address modal */}
      <div id="update" className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Update Address
              </h5>
              <Link to="#" className="close" data-dismiss="modal" aria-label="Close" id="close">
                <em className="icon ni ni-cross" />
              </Link>
            </div>
            <div className="modal-body">
              <form onSubmit={updateAddress}>
                <div className="form-group mb-3">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    id="name"
                    placeholder="Enter Name"
                    name="name"
                    value={address.name}
                    onChange={(e) => setAddress({ ...address, name: e.target.value })}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="name">Street</label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    id="street"
                    placeholder="Enter Street"
                    name="street"
                    value={address.street}
                    onChange={(e) => setAddress({ ...address, street: e.target.value })}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="name">Street 2 (optional)</label>
                  <input
                    type="text"
                    className="form-control"
                    id="street2"
                    placeholder="Enter Street 2"
                    name="street2"
                    value={address.street2}
                    onChange={(e) => setAddress({ ...address, street2: e.target.value })}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="name">ZIP Code</label>
                  <input
                    type="number"
                    required
                    className="form-control"
                    id="zip"
                    placeholder="Enter zip"
                    name="zip"
                    value={address.zip}
                    onChange={(e) => setAddress({ ...address, zip: e.target.value })}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="name">City</label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    id="city"
                    placeholder="Enter City"
                    name="city"
                    value={address.city}
                    onChange={(e) => setAddress({ ...address, city: e.target.value })}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="name">State</label>
                  <select
                    name="state"
                    id="state"
                    className="form-control"
                    required
                    value={address.state}
                    onChange={(e) => setAddress({ ...address, state: e.target.value })}
                  >
                    <option value="">Select state</option>
                    {states.map((state, index) => (
                      <option key={index} value={state.ID}>
                        {state.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="exampleInputEmail1">Country</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter Country"
                    name="country"
                    value="United States"
                    disabled
                  />
                </div>
                <div className="d-flex mt-2 justify-content-end ">
                  <button type="button" className="btn btn-dark mr-3" data-dismiss="modal">
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Update {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* delete address modal */}
      <div id="delete" className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-top" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Delete Address
              </h5>
              <Link to="#" className="close" data-dismiss="modal" aria-label="Close" id="close">
                <em className="icon ni ni-cross" />
              </Link>
            </div>
            <div className="modal-body">
              <h6 className="p-3">Are you sure you want to delete this address?</h6>
              <div className="d-flex mt-2 justify-content-end ">
                <button type="button" className="btn btn-dark mr-3" data-dismiss="modal">
                  Close
                </button>
                <button type="button" className="btn btn-danger" onClick={(e) => deleteAddress(e)}>
                  Delete {loader}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Address;
