import React, { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { UserContext } from "../App";

const Header = (props) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const UserAccess = useContext(UserContext);

  useEffect(() => {
    let { userInfo } = UserAccess;
    setEmail(userInfo?.email);
    setName(userInfo?.username);
  }, [UserAccess]);

  return (
    <div className="nk-header nk-header-fixed is-light">
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ml-n1">
            <a
              href="#"
              className="nk-nav-toggle nk-quick-nav-icon"
              onClick={() =>
                props.sidebarRef.current?.classList.toggle("nk-sidebar-active")
              }
            >
              <em className="icon ni ni-menu" />
            </a>
          </div>

          <div className="nk-header-brand d-xl-none">
            <a href="/" className="logo-link">
              <img
                className="logo-dark logo-img"
                src="/assets/logo.png"
                alt="logo-dark"
              />
            </a>
          </div>

          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li className="dropdown user-dropdown">
                <a
                  href="#"
                  className="dropdown-toggle mr-n1"
                  data-toggle="dropdown"
                >
                  <div className="user-toggle">
                    <div className="user-avatar sm">
                      <em className="icon ni ni-user-alt" />
                    </div>
                    <div className="user-info d-none d-xl-block">
                      <div className="user-name dropdown-indicator">{name}</div>
                    </div>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                  <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                    <div className="user-card">
                      <div className="user-info">
                        <span className="lead-text">{name}</span>
                        <span className="sub-text">{email}</span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="dropdown-inner">
                    <ul className="link-list">
                      <li>
                        <Link to="/settings">
                          <em className="icon ni ni-setting-alt" />
                          <span>Account Setting</span>
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                  <div className="dropdown-inner">
                    <ul className="link-list">
                      <li>
                        <Link
                          to=""
                          onClick={() => {
                            Cookies.remove("token");
                            window.location.reload();
                          }}
                        >
                          <em className="icon ni ni-signout" />
                          <span>Sign out</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* .nk-header-wrap */}
      </div>
      {/* .container-fliud */}
    </div>
  );
};

export default Header;
