import Sidebar from "./components/Sidebar";
import React, { useState, useEffect, useRef } from "react";
import DashboardRoutes from "./routes/DashboardRoutes";
import { Routes, Route } from "react-router-dom";
import AuthRouter from "./routes/AuthRouter";
import { server, config } from "./env";
import axios from "axios";
import PageLoader from "./components/PageLoader";
import "./App.css";

export const UserContext = React.createContext();

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const [isAllLoaded, setIsAllLoaded] = useState(false);
  const sidebarRef = useRef();
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    checkUserLogin();
  }, []);

  const checkUserLogin = async () => {
    await axios(server + "/api/v1/auth/user-access", config)
      .then((rsp) => {
        setIsLogin(true);
        setIsAllLoaded(true);
        setUserInfo(rsp.data);
      })
      .catch((err) => {
        setIsLogin(false);
        setIsAllLoaded(true);
      });
  };

  return isAllLoaded ? (
    isLogin ? (
      <UserContext.Provider value={{ userInfo, setUserInfo, checkUserLogin }}>
        <div className="nk-app-root">
          <div className="nk-main ">
            <Sidebar sidebarRef={sidebarRef} />
            <Routes>
              <Route
                path="*"
                element={<DashboardRoutes sidebarRef={sidebarRef} />}
              />
            </Routes>
          </div>
        </div>
      </UserContext.Provider>
    ) : (
      <Routes>
        <Route path="*" element={<AuthRouter />} />
      </Routes>
    )
  ) : (
    <PageLoader />
  );
}

export default App;
