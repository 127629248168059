import React, { useState, useEffect, useContext } from "react";
import Header from "../components/Header";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { server, config, downloadConfig } from "../env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";
import { UserContext } from "../App";

const Dashboard = (props) => {
  const [orders, setOrders] = useState([]);
  const [dasboard, setDasboard] = useState({});
  const [filter, setFilter] = useState("All");
  const [sort, setSort] = useState("des");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState({});
  const [orderStats, setOrderStats] = useState({});
  const [loader, setLoader] = useState("");

  const UserAccess = useContext(UserContext);

  useEffect(() => {
    let { userInfo } = UserAccess;
    setDasboard(userInfo);
  }, [UserAccess]);

  const getOrders = async (search) => {
    let link = `${server}/api/v1/orders/readOrders?status=${filter}&sort=${sort}&page=${page}&limit=${limit}`;

    if (search) {
      link = `${server}/api/v1/orders/readOrders?status=${filter}&sort=${sort}&page=${page}&limit=${limit}&search=${search}`;
    }

    await axios
      .get(`${link}`, config)
      .then((res) => {
        setOrders(res.data.orders);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getOrdersStats = async () => {
    let link = `${server}/api/v1/auth/orders-stats`;

    await axios
      .get(`${link}`, config)
      .then((res) => {
        setOrderStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadPdf = async (id) => {
    await axios
      .get(`${server}/api/v1/orders/downloadOrder/${id}`, downloadConfig)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "label.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getOrders();
    getOrdersStats();
  }, [filter, sort, page]);

  const duplicateOrder = async (id) => {
    setLoader(<Loader />);

    await axios
      .post(server + "/api/v1/order/duplicate/" + id, {}, config)
      .then((res) => {
        toast.success(res.data.message);
        setLoader("");
        getOrders();
        document.getElementById("close").click();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoader("");
      });
  };

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Dashboard</h3>
                  </div>
                </div>
              </div>
              {/* .nk-block-head */}
              <div className="nk-block">
                <div className="row g-gs ">
                  <div className="col-xxl-4  col-md-6">
                    <div className="card h-100 border-primary">
                      <div className="nk-ecwg nk-ecwg6">
                        <div className="card-inner">
                          <div className="card-title-group">
                            <div className="card-title">
                              <h6 className="title">Orders</h6>
                            </div>
                          </div>
                          <div className="data">
                            <div className="data-group">
                              <div className="amount">{orderStats.orders ? orderStats.orders : 0}</div>
                            </div>
                          </div>
                        </div>
                        {/* .card-inner */}
                      </div>
                      {/* .nk-ecwg */}
                    </div>
                    {/* .card */}
                  </div>
                  <div className="col-xxl-4  col-md-6">
                    <div className="card  h-100 border-primary">
                      <div className="nk-ecwg nk-ecwg6">
                        <div className="card-inner">
                          <div className="card-title-group">
                            <div className="card-title">
                              <h6 className="title">Balance</h6>
                            </div>
                          </div>

                          <div className="data">
                            <div className="data-group">
                              <div className="amount">${dasboard.balance?.toFixed(2) || 0}</div>
                              <div className="nk-ecwg6-ck">
                                <canvas className="ecommerce-line-chart-s3" id="todayOrders" />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* .card-inner */}
                      </div>
                      {/* .nk-ecwg */}
                    </div>
                    {/* .card */}
                  </div>
                </div>

                <div className="nk-block nk-block-lg mt-5">
                  <div className=" d-flex justify-content-start">
                    <div className="nk-block-head-content">
                      <h4 className="nk-block-title">Order History</h4>
                    </div>
                  </div>
                  <span className="text-muted">{total} orders</span>
                  <div className="card-filter mt-3">
                    <div className="row justify-content-between">
                      {/* </div>
                    <div className="d-flex flex-column flex-md-row  align-items-center"> */}
                      <div className="col-12 col-md-6 col-lg-4 ">
                        <div className="d-flex justify-content-between justify-content-md-start">
                          <div className="form-group ml-2 ">
                            <select
                              name="sort"
                              id=""
                              className="form-control"
                              value={sort}
                              onChange={(e) => {
                                setSort(e.target.value);
                              }}
                            >
                              <option value="asc">Sort by Date Ascending</option>
                              <option value="des">Sort by Date Descending</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {/* .search */}
                      <div className="col-12 col-md-6 col-lg-4  mt-3 mt-md-0">
                        <div className="d-flex justify-content-between">
                          <div className="form-group col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              onChange={(e) => {
                                getOrders(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card card-preview mt-2 mb-3">
                    <div className="table-responsive">
                      <table className="table ">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">From</th>
                            <th scope="col">To</th>
                            <th scope="col">type</th>
                            <th scope="col">Amount</th>
                            {/* <th scope="col">Status</th> */}
                            <th scope="col">Tracking</th>
                            {/* <th scope="col">Tracking Status</th> */}
                            <th scope="col">Date</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders.map((order, index) => (
                            <tr key={index}>
                              <th scope="row">{order._id}</th>
                              <td>{order.fromName}</td>
                              <td>{order.toName}</td>
                              <td>{order.labelType?.name}</td>
                              <td>${order.price}</td>
                              {/* <td>{order.status}</td> */}
                              <td>{order.tracking}</td>
                              {/* <td>{order.statusMessage || "N/A"}</td> */}
                              <td>{new Date(order.createdAt).toLocaleString()}</td>
                              <td>
                                {/* download */}
                                <Link
                                  to=""
                                  className="btn btn-sm btn-info me-2 px-2 py-1"
                                  onClick={(e) => {
                                    downloadPdf(order._id);
                                  }}
                                >
                                  <em className="icon ni ni-download" />
                                </Link>
                                {/* Duplicate order */}
                                {/* <Link
                                  to="#"
                                  className="btn btn-sm btn-warning me-2 px-2 py-1 ml-2"
                                  data-toggle="modal"
                                  data-target="#duplicate"
                                  onClick={(e) => {
                                    setOrder(order);
                                  }}
                                >
                                  <em className="icon ni ni-copy" />
                                </Link> */}

                                {/* {order.labelType.name.includes("USPS") && (
                                  <a
                                    href={`https://tools.usps.com/go/TrackConfirmAction?tLabels=${order.tracking}`}
                                    target="_blank"
                                    className="btn btn-sm btn-success me-2 px-2 py-1 ml-2"
                                    rel="noreferrer"
                                  >
                                    <em className="icon ni ni-link" />
                                  </a>
                                )} */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                    <nav>
                      <ul className="pagination">
                        <li className={page === 1 ? "page-item disabled" : "page-item"}>
                          <Link
                            className="page-link"
                            to=""
                            onClick={(e) => {
                              setPage(page - 1);
                            }}
                          >
                            Prev
                          </Link>
                        </li>

                        <li className="page-item active" aria-current="page">
                          <Link className="page-link" to="">
                            {page} <span className="sr-only">(current)</span>
                          </Link>
                        </li>

                        <li className={page === totalPages ? "page-item disabled" : "page-item"}>
                          <Link
                            className="page-link"
                            to=""
                            tabIndex={-1}
                            aria-disabled={page === totalPages ? true : false}
                            onClick={(e) => {
                              setPage(page + 1);
                            }}
                          >
                            Next
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

      <div
        id="duplicate"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Duplicate Order
              </h5>
              <Link to="#" className="close" data-dismiss="modal" aria-label="Close" id="close">
                <em className="icon ni ni-cross" />
              </Link>
            </div>
            <div className="modal-body">
              <p>Are you sure you would like to order a duplicate of this label order? you will be charged ${order.price} again .</p>

              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={(e) => {
                    duplicateOrder(order._id);
                  }}
                >
                  Yes {loader}
                </button>
                <button className="btn btn-secondary btn-sm ml-2" data-dismiss="modal">
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
