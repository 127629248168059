import React from "react";
import { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";

const CreateOrder = (props) => {
  const navigate = useNavigate();
  const [address, setAddress] = useState({});
  const [address1, setAddress1] = useState({});
  const [dasboard, setDasboard] = useState({});
  const [access, setAccess] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [Type_id, setType_id] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [Weight, setWeight] = useState(0);
  const [types, setTypes] = useState([]);

  const [savedAddress, setSavedAddress] = useState([]);

  const [states, setStates] = useState([
    {
      ID: "AK",
      Name: "Alaska",
      Country: "US",
    },
    {
      ID: "AL",
      Country: "US",
      Name: "Alabama",
    },
    {
      ID: "AR",
      Country: "US",
      Name: "Arkansas",
    },
    {
      ID: "AZ",
      Country: "US",
      Name: "Arizona",
    },
    {
      ID: "CA",
      Country: "US",
      Name: "California",
    },
    {
      ID: "CO",
      Country: "US",
      Name: "Colorado",
    },
    {
      ID: "CT",
      Country: "US",
      Name: "Connecticut",
    },
    {
      ID: "DC",
      Country: "US",
      Name: "District Of Columbia",
    },
    {
      ID: "DE",
      Country: "US",
      Name: "Delaware",
    },
    {
      ID: "FL",
      Country: "US",
      Name: "Florida",
    },
    {
      ID: "GA",
      Country: "US",
      Name: "Georgia",
    },
    {
      ID: "HI",
      Country: "US",
      Name: "Hawaii",
    },
    {
      ID: "IA",
      Country: "US",
      Name: "Iowa",
    },
    {
      ID: "ID",
      Country: "US",
      Name: "Idaho",
    },
    {
      ID: "IL",
      Country: "US",
      Name: "Illinois",
    },
    {
      ID: "IN",
      Country: "US",
      Name: "Indiana",
    },
    {
      ID: "KS",
      Country: "US",
      Name: "Kansas",
    },
    {
      ID: "KY",
      Country: "US",
      Name: "Kentucky",
    },
    {
      ID: "LA",
      Country: "US",
      Name: "Louisiana",
    },
    {
      ID: "MA",
      Country: "US",
      Name: "Massachusetts",
    },
    {
      ID: "MD",
      Country: "US",
      Name: "Maryland",
    },
    {
      ID: "ME",
      Country: "US",
      Name: "Maine",
    },
    {
      ID: "MI",
      Name: "Michigan",
      Country: "US",
    },
    {
      ID: "MN",
      Country: "US",
      Name: "Minnesota",
    },
    {
      ID: "MO",
      Country: "US",
      Name: "Missouri",
    },
    {
      ID: "MS",
      Country: "US",
      Name: "Mississippi",
    },
    {
      ID: "MT",
      Country: "US",
      Name: "Montana",
    },
    {
      ID: "NC",
      Country: "US",
      Name: "North Carolina",
    },
    {
      ID: "ND",
      Country: "US",
      Name: "North Dakota",
    },
    {
      ID: "NE",
      Country: "US",
      Name: "Nebraska",
    },
    {
      ID: "NH",
      Country: "US",
      Name: "New Hampshire",
    },
    {
      ID: "NJ",
      Country: "US",
      Name: "New Jersey",
    },
    {
      ID: "NM",
      Country: "US",
      Name: "New Mexico",
    },
    {
      ID: "NV",
      Country: "US",
      Name: "Nevada",
    },
    {
      ID: "NY",
      Country: "US",
      Name: "New York",
    },
    {
      ID: "OH",
      Name: "Ohio",
      Country: "US",
    },
    {
      ID: "OK",
      Name: "Oklahoma",
      Country: "US",
    },
    {
      ID: "OR",
      Name: "Oregon",
      Country: "US",
    },
    {
      ID: "PA",
      Name: "Pennsylvania",
      Country: "US",
    },
    {
      ID: "PR",
      Country: "US",
      Name: "Puerto Rico",
    },
    {
      ID: "RI",
      Country: "US",
      Name: "Rhode Island",
    },
    {
      ID: "SC",
      Country: "US",
      Name: "South Carolina",
    },
    {
      ID: "SD",
      Country: "US",
      Name: "South Dakota",
    },
    {
      ID: "TN",
      Country: "US",
      Name: "Tennessee",
    },
    {
      ID: "TX",
      Country: "US",
      Name: "Texas",
    },
    {
      ID: "UT",
      Country: "US",
      Name: "Utah",
    },
    {
      ID: "VA",
      Country: "US",
      Name: "Virginia",
    },
    {
      ID: "VT",
      Country: "US",
      Name: "Vermont",
    },
    {
      ID: "WA",
      Country: "US",
      Name: "Washington",
    },
    {
      ID: "WI",
      Country: "US",
      Name: "Wisconsin",
    },
    {
      ID: "WV",
      Country: "US",
      Name: "West Virginia",
    },
    {
      ID: "WY",
      Country: "US",
      Name: "Wyoming",
    },

    // write canada provinces here
    {
      ID: "AB",
      Country: "CA",
      Name: "Alberta",
    },
    {
      ID: "BC",
      Country: "CA",
      Name: "British Columbia",
    },
    {
      ID: "MB",
      Country: "CA",
      Name: "Manitoba",
    },
    {
      ID: "NB",
      Country: "CA",
      Name: "New Brunswick",
    },
    {
      ID: "NL",
      Country: "CA",
      Name: "Newfoundland and Labrador",
    },
    {
      ID: "NS",
      Country: "CA",
      Name: "Nova Scotia",
    },
    {
      ID: "NT",
      Country: "CA",
      Name: "Northwest Territories",
    },
    {
      ID: "NU",
      Country: "CA",
      Name: "Nunavut",
    },
    {
      ID: "ON",
      Country: "CA",
      Name: "Ontario",
    },
    {
      ID: "PE",
      Country: "CA",
      Name: "Prince Edward Island",
    },
    {
      ID: "QC",
      Country: "CA",
      Name: "Quebec",
    },
    {
      ID: "SK",
      Country: "CA",
      Name: "Saskatchewan",
    },
    {
      ID: "YT",
      Country: "CA",
      Name: "Yukon",
    },
  ]);

  const [From, setFrom] = useState({
    FromName: "",
    FromStreet: "",
    FromStreet2: "",
    FromCity: "",
    FromState: "",
    FromZip: "",
    FromCompany: "",
    FromCountry: "",
  });
  const [To, setTo] = useState({
    ToName: "",
    ToStreet: "",
    ToStreet2: "",
    ToCity: "",
    ToState: "",
    ToZip: "",
    ToCompany: "",
    ToCountry: "",
  });

  useEffect(() => {
    readLabelTypes();
    getAddresses();
  }, []);

  const readLabelTypes = async () => {
    await axios
      .get(server + "/api/v1/labeltype/read", config)
      .then((res) => {
        setTypes(res.data.labelTypes);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAddresses = async () => {
    await axios
      .get(server + "/api/v1/address/readAll", config)
      .then((res) => {
        setSavedAddress(res.data.addresses);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setFrom({
      FromName: address.name,
      FromStreet: address.street,
      FromStreet2: address.street2,
      FromCity: address.city,
      FromState: address.state,
      FromZip: address.zip,
      FromCompany: address.company,
      FromCountry: address.country,
    });
  }, [address]);
  useEffect(() => {
    setTo({
      ToName: address1.name,
      ToStreet: address1.street,
      ToStreet2: address1.street2,
      ToCity: address1.city,
      ToState: address1.state,
      ToZip: address1.zip,
      ToCompany: address1.company,
      ToCountry: address1.country,
    });
  }, [address1]);

  useEffect(() => {
    console.log(Type_id);
  }, [Type_id]);

  const createOrder = async (e) => {
    e.preventDefault();
    setIsDisabled(true);

    const data = {
      // price: finalPrice,
      labelType: e.target.type.value,
      template: e.target.template.value,
      weight: e.target.Weight.value,
      fromName: From.FromName,
      fromStreet: From.FromStreet,
      fromStreet2: From.FromStreet2,
      fromCity: From.FromCity,
      fromState: From.FromState,
      fromZip: From.FromZip,
      fromCompany: From.FromCompany,
      fromCountry: e.target.FromCountry.value,
      toName: To.ToName,
      toStreet: To.ToStreet,
      toStreet2: To.ToStreet2,
      toCity: To.ToCity,
      toState: To.ToState,
      toZip: To.ToZip,
      toCompany: To.ToCompany,
      toCountry: e.target.ToCountry.value,
      height: e.target?.height?.value || 0,
      width: e.target?.width?.value || 0,
      length: e.target?.length?.value || 0,
      // description: e.target.description?.value || "",
      fromPhone: e.target.FromPhone.value,
      toPhone: e.target.ToPhone.value,

      // fedex int
      // CustomsItems: customItems,
      // CustomsPrice: totalPrice,
      // ScheduleDate: e.target.scheduleDate?.value || "",
      // SignatureRequired: signatureRequired,
      // ScheduleEnabled: scheduleEnabled,
      // Class: e.target.class?.value || "",
    };

    await axios
      .post(server + "/api/v1/orders/createOrder", data, config)
      .then((res) => {
        toast.success("Order created successfully");
        navigate("/");
        setIsDisabled(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setIsDisabled(false);
      });
  };

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className=" d-flex justify-content-between">
                  <div className="nk-block-head-content">
                    <h4 className="nk-block-title">Create Order</h4>
                  </div>
                </div>
              </div>

              <form onSubmit={createOrder}>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="row">
                              <div
                                className={
                                  "col-12 " +
                                  (selectedType === "USPS Priority"
                                    ? "col-md-6"
                                    : "")
                                }
                              >
                                <div className="form-group mb-3">
                                  <label>Type</label>
                                  <select
                                    required
                                    value={Type_id}
                                    name="type"
                                    id=""
                                    className="form-control"
                                    onChange={(e) => {
                                      setType_id(e.target.value);
                                      if (e.target.value !== "") {
                                        setSelectedType(
                                          types.find((type) => {
                                            return type._id === e.target.value;
                                          }).name
                                        );
                                      } else {
                                        setSelectedType("");
                                      }
                                    }}
                                  >
                                    <option value="">Select type</option>
                                    {types.map((type) => (
                                      <option key={type._id} value={type._id}>
                                        {type.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              {selectedType === "USPS Priority" && (
                                <div className="col-12 col-md-6">
                                  <div className="form-group mb-3">
                                    <label>Template</label>
                                    <select
                                      required
                                      name="template"
                                      className="form-control"
                                    >
                                      <option value=""> Select type</option>
                                      <option value="indica">Indica</option>
                                      <option value="stamps">Stamps</option>
                                      <option value="evs">EVS</option>
                                      <option value="normal">Normal</option>
                                    </select>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="order-id">Weight</label>
                              <input
                                type="number"
                                name="Weight"
                                id="Weight"
                                className="form-control"
                                placeholder="Weight"
                                required
                                onChange={(e) => {
                                  setWeight(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <h2 className="card-title text-center">From</h2>
                        <div className="form-group mb-3">
                          <label htmlFor="order-id">
                            Select a saved address
                          </label>
                          <select
                            name="address"
                            id=""
                            className="form-control"
                            onChange={(e) => {
                              // setAddress(savedAddress.find((a) => a._id === e.target.value));
                            }}
                          >
                            <option value="">Select address</option>
                            {savedAddress.map((address) => (
                              <option key={address._id} value={address._id}>
                                {address.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="order-id">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Enter name"
                            name="FromName"
                            value={From.FromName}
                            onChange={(e) => {
                              setFrom({ ...From, FromName: e.target.value });
                            }}
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="order-id">Company</label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Enter company name (optional)"
                            name="FromCompany"
                            value={From.FromCompany}
                            onChange={(e) => {
                              setFrom({
                                ...From,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="order-id">Street</label>
                          <input
                            type="text"
                            className="form-control"
                            id="fromAddress"
                            placeholder="Address"
                            name="FromStreet"
                            value={From.FromStreet}
                            onChange={(e) => {
                              setFrom({
                                ...From,
                                [e.target.name]: e.target.value,
                              });
                            }}
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="order-id">Street 2 (optional)</label>
                          <input
                            type="text"
                            className="form-control"
                            id="fromAddress2"
                            placeholder="Address line 2"
                            name="FromStreet2"
                            value={From.FromStreet2}
                            onChange={(e) => {
                              setFrom({
                                ...From,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="order-id">ZIP Code</label>
                          <input
                            type="text"
                            className="form-control"
                            id="fromzipCode"
                            placeholder="ZipCode"
                            name="FromZip"
                            required
                            value={From.FromZip}
                            onChange={(e) => {
                              setFrom({
                                ...From,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="order-id">City</label>
                          <input
                            type="text"
                            className="form-control"
                            id="fromCity"
                            placeholder="City"
                            name="FromCity"
                            required
                            value={From.FromCity}
                            onChange={(e) => {
                              setFrom({
                                ...From,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="order-id">State</label>
                          <select
                            name="FromState"
                            id="FromState"
                            className="form-control"
                            required
                            value={From.FromState}
                            onChange={(e) => {
                              setFrom({
                                ...From,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          >
                            <option value="">Select state</option>
                            {states
                              .filter((state) => state.Country === "US")
                              .map((state, index) => (
                                <option key={index} value={state.ID}>
                                  {state.Name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="order-id">Country</label>
                          <select
                            className="form-control"
                            name="FromCountry"
                            placeholder="Country"
                            required
                            disabled
                          >
                            {
                              <option value="US" selected>
                                United States
                              </option>
                            }
                          </select>
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="order-id">Phone</label>
                          <input
                            type="Number"
                            className="form-control"
                            name="FromPhone"
                            placeholder="Phone"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <h2 className="card-title text-center">To</h2>

                        <div className="form-group mb-3">
                          <label htmlFor="order-id">
                            Select a saved address
                          </label>
                          <select
                            name="address"
                            id=""
                            className="form-control"
                            onChange={(e) => {
                              setAddress1(
                                savedAddress.find(
                                  (a) => a._id === e.target.value
                                )
                              );
                            }}
                          >
                            <option value="">Select address</option>
                            {savedAddress.map((address) => (
                              <option key={address._id} value={address._id}>
                                {address.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="order-id">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Enter name"
                            name="ToName"
                            required
                            value={To.ToName}
                            onChange={(e) => {
                              setTo({ ...To, [e.target.name]: e.target.value });
                            }}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="order-id">Company</label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Enter company name (optional)"
                            name="ToCompany"
                            value={To.ToCompany}
                            onChange={(e) => {
                              setTo({ ...To, [e.target.name]: e.target.value });
                            }}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="order-id">Street</label>
                          <input
                            type="text"
                            className="form-control"
                            id="toAddress"
                            placeholder="Address"
                            name="ToStreet"
                            required
                            value={To.ToStreet}
                            onChange={(e) => {
                              setTo({ ...To, [e.target.name]: e.target.value });
                            }}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="order-id">Street 2 (optional)</label>
                          <input
                            type="text"
                            className="form-control"
                            id="ToStreet2"
                            placeholder="Address line 2"
                            name="ToStreet2"
                            value={To.ToStreet2}
                            onChange={(e) => {
                              setTo({ ...To, [e.target.name]: e.target.value });
                            }}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="order-id">ZIP Code</label>
                          <input
                            type="text"
                            className="form-control"
                            id="ToZip"
                            placeholder="Zip Code"
                            name="ToZip"
                            required
                            value={To.ToZip}
                            onChange={(e) => {
                              setTo({ ...To, [e.target.name]: e.target.value });
                            }}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="order-id">City</label>
                          <input
                            type="text"
                            className="form-control"
                            id="ToCity"
                            placeholder="City"
                            name="ToCity"
                            required
                            value={To.ToCity}
                            onChange={(e) => {
                              setTo({ ...To, [e.target.name]: e.target.value });
                            }}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="order-id">State</label>
                          <select
                            name="ToState"
                            id="ToState"
                            className="form-control"
                            required
                            value={To.ToState}
                            onChange={(e) => {
                              setTo({ ...To, [e.target.name]: e.target.value });
                            }}
                          >
                            <option value="">Select state</option>
                            {states
                              .filter((state) => state.Country === "US")
                              .map((state, index) => (
                                <option key={index} value={state.ID}>
                                  {state.Name}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="order-id">Country</label>
                          <select
                            className="form-control"
                            name="ToCountry"
                            placeholder="Country"
                            required
                            disabled
                          >
                            {
                              <option value="US" selected>
                                United States
                              </option>
                            }
                          </select>
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="order-id">Phone</label>
                          <input
                            type="Number"
                            className="form-control"
                            name="ToPhone"
                            placeholder="Phone"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {
                  <div className="row mt-3 mb-3">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-header">
                          <h4>Package Dimensions</h4>
                        </div>
                        <div className="card-body">
                          {
                            <>
                              <div className="form-group">
                                <label htmlFor="floatingInput">
                                  Height
                                  <i> (inch)</i>
                                </label>
                                <input
                                  type="Number"
                                  name="height"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Weight"
                                  required
                                />
                              </div>{" "}
                              <div className="form-group">
                                <label htmlFor="floatingInput">
                                  Width
                                  <i> (inch)</i>
                                </label>
                                <input
                                  type="Number"
                                  name="width"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Weight"
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="floatingInput">
                                  Length
                                  <i> (inch)</i>
                                </label>
                                <input
                                  type="Number"
                                  name="length"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="length"
                                  required
                                />
                              </div>{" "}
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                }

                {/* show balance and button to create order */}
                <div className="d-flex justify-content-between mt-5">
                  <div className="d-flex justify-content-center"></div>

                  <button
                    className="btn btn-primary btn-xl "
                    type="submit"
                    disabled={isDisabled}
                  >
                    Create Order
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateOrder;
