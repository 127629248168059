import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config, downloadConfig } from "../env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";
const CsvOrders = (props) => {
  const [From, setFrom] = useState({});
  const [states, setStates] = useState([
    {
      ID: "AK",
      Name: "Alaska",
      Country: "US",
    },
    {
      ID: "AL",
      Country: "US",
      Name: "Alabama",
    },
    {
      ID: "AR",
      Country: "US",
      Name: "Arkansas",
    },
    {
      ID: "AZ",
      Country: "US",
      Name: "Arizona",
    },
    {
      ID: "CA",
      Country: "US",
      Name: "California",
    },
    {
      ID: "CO",
      Country: "US",
      Name: "Colorado",
    },
    {
      ID: "CT",
      Country: "US",
      Name: "Connecticut",
    },
    {
      ID: "DC",
      Country: "US",
      Name: "District Of Columbia",
    },
    {
      ID: "DE",
      Country: "US",
      Name: "Delaware",
    },
    {
      ID: "FL",
      Country: "US",
      Name: "Florida",
    },
    {
      ID: "GA",
      Country: "US",
      Name: "Georgia",
    },
    {
      ID: "HI",
      Country: "US",
      Name: "Hawaii",
    },
    {
      ID: "IA",
      Country: "US",
      Name: "Iowa",
    },
    {
      ID: "ID",
      Country: "US",
      Name: "Idaho",
    },
    {
      ID: "IL",
      Country: "US",
      Name: "Illinois",
    },
    {
      ID: "IN",
      Country: "US",
      Name: "Indiana",
    },
    {
      ID: "KS",
      Country: "US",
      Name: "Kansas",
    },
    {
      ID: "KY",
      Country: "US",
      Name: "Kentucky",
    },
    {
      ID: "LA",
      Country: "US",
      Name: "Louisiana",
    },
    {
      ID: "MA",
      Country: "US",
      Name: "Massachusetts",
    },
    {
      ID: "MD",
      Country: "US",
      Name: "Maryland",
    },
    {
      ID: "ME",
      Country: "US",
      Name: "Maine",
    },
    {
      ID: "MI",
      Name: "Michigan",
      Country: "US",
    },
    {
      ID: "MN",
      Country: "US",
      Name: "Minnesota",
    },
    {
      ID: "MO",
      Country: "US",
      Name: "Missouri",
    },
    {
      ID: "MS",
      Country: "US",
      Name: "Mississippi",
    },
    {
      ID: "MT",
      Country: "US",
      Name: "Montana",
    },
    {
      ID: "NC",
      Country: "US",
      Name: "North Carolina",
    },
    {
      ID: "ND",
      Country: "US",
      Name: "North Dakota",
    },
    {
      ID: "NE",
      Country: "US",
      Name: "Nebraska",
    },
    {
      ID: "NH",
      Country: "US",
      Name: "New Hampshire",
    },
    {
      ID: "NJ",
      Country: "US",
      Name: "New Jersey",
    },
    {
      ID: "NM",
      Country: "US",
      Name: "New Mexico",
    },
    {
      ID: "NV",
      Country: "US",
      Name: "Nevada",
    },
    {
      ID: "NY",
      Country: "US",
      Name: "New York",
    },
    {
      ID: "OH",
      Name: "Ohio",
      Country: "US",
    },
    {
      ID: "OK",
      Name: "Oklahoma",
      Country: "US",
    },
    {
      ID: "OR",
      Name: "Oregon",
      Country: "US",
    },
    {
      ID: "PA",
      Name: "Pennsylvania",
      Country: "US",
    },
    {
      ID: "PR",
      Country: "US",
      Name: "Puerto Rico",
    },
    {
      ID: "RI",
      Country: "US",
      Name: "Rhode Island",
    },
    {
      ID: "SC",
      Country: "US",
      Name: "South Carolina",
    },
    {
      ID: "SD",
      Country: "US",
      Name: "South Dakota",
    },
    {
      ID: "TN",
      Country: "US",
      Name: "Tennessee",
    },
    {
      ID: "TX",
      Country: "US",
      Name: "Texas",
    },
    {
      ID: "UT",
      Country: "US",
      Name: "Utah",
    },
    {
      ID: "VA",
      Country: "US",
      Name: "Virginia",
    },
    {
      ID: "VT",
      Country: "US",
      Name: "Vermont",
    },
    {
      ID: "WA",
      Country: "US",
      Name: "Washington",
    },
    {
      ID: "WI",
      Country: "US",
      Name: "Wisconsin",
    },
    {
      ID: "WV",
      Country: "US",
      Name: "West Virginia",
    },
    {
      ID: "WY",
      Country: "US",
      Name: "Wyoming",
    },

    // write canada provinces here
    {
      ID: "AB",
      Country: "CA",
      Name: "Alberta",
    },
    {
      ID: "BC",
      Country: "CA",
      Name: "British Columbia",
    },
    {
      ID: "MB",
      Country: "CA",
      Name: "Manitoba",
    },
    {
      ID: "NB",
      Country: "CA",
      Name: "New Brunswick",
    },
    {
      ID: "NL",
      Country: "CA",
      Name: "Newfoundland and Labrador",
    },
    {
      ID: "NS",
      Country: "CA",
      Name: "Nova Scotia",
    },
    {
      ID: "NT",
      Country: "CA",
      Name: "Northwest Territories",
    },
    {
      ID: "NU",
      Country: "CA",
      Name: "Nunavut",
    },
    {
      ID: "ON",
      Country: "CA",
      Name: "Ontario",
    },
    {
      ID: "PE",
      Country: "CA",
      Name: "Prince Edward Island",
    },
    {
      ID: "QC",
      Country: "CA",
      Name: "Quebec",
    },
    {
      ID: "SK",
      Country: "CA",
      Name: "Saskatchewan",
    },
    {
      ID: "YT",
      Country: "CA",
      Name: "Yukon",
    },
  ]);
  const [loader, setLoader] = useState("");
  const [csvpaydisabled, setCsvpaydisabled] = useState(true);
  const [csvPrice, setCsvPrice] = useState(0);
  const [validateLoader, setValidateLoader] = useState("");
  const [CSV, setCSV] = useState("");
  const [types, setTypes] = useState([]);
  const [activeUspsType, setActiveUspsType] = useState();
  const [uspsValidateDisabled, setUspsValidateDisabled] = useState(false);
  const [Weight, setWeight] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [activeType, setActiveType] = useState("");
  const [csvOrders, setCsvOrders] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const [templateType, setTemplateType] = useState("");
  useEffect(() => {
    readLabelTypes();
    readCsvOrders();
  }, []);

  const readLabelTypes = async () => {
    await axios
      .get(server + "/api/v1/labeltype/read", config)
      .then((res) => {
        setTypes(res.data.labelTypes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateCSV = async (e) => {
    e.preventDefault();

    if (!activeUspsType) {
      toast.error("Please select a label type");
      return;
    }
    if (selectedType && selectedType === "USPS Priority" && !templateType) {
      toast.error("Please select template type");
      return;
    }
    if (!CSV) {
      toast.error("Please upload a CSV file");
      return;
    }

    setValidateLoader(<Loader />);

    const params = new FormData();
    params.append("csv", CSV);
    params.append("template", templateType);
    params.append("type", activeUspsType);
    // params.append("weight", Weight);

    await axios
      .post(server + "/api/v1/orders/validate-csv", params, config)
      .then((res) => {
        setValidateLoader("");
        setCsvPrice(res.data.totalPrice);
        setCsvpaydisabled(false);
        toast.success("CSV validated successfully");
      })
      .catch((err) => {
        setValidateLoader("");
        toast.error(err.response.data.message);
      });
  };

  const createOrderFromCSV = async (e) => {
    e.preventDefault();

    setLoader(<Loader />);

    const params = new FormData();
    params.append("csv", CSV);
    params.append("type", activeUspsType);

    await axios
      .post(server + "/api/v1/orders/upload-csv", params, config)
      .then((res) => {
        toast.success("Orders created successfully");
        setCsvpaydisabled(true);
        setCSV("");
        setWeight(0);
        setActiveType("");
        setCsvPrice(0);
        setLoader("");
        readCsvOrders();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoader("");
      });
  };

  const readCsvOrders = async () => {
    await axios
      .get(server + "/api/v1/orders/read-bulk-orders", config)
      .then((res) => {
        setCsvOrders(res.data.orders);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadZip = async (id) => {
    await axios
      .get(`${server}/api/v1/orders/downloadZipOrder/${id}`, downloadConfig)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "label.zip"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Create CSV Order</h5>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-12 col-md-6 ">
                          <div className="form-group mb-3">
                            <label>Type</label>
                            <select
                              required
                              name="type"
                              id=""
                              className="form-control"
                              onChange={(e) => {
                                setActiveUspsType(e.target.value);

                                if (e.target.value !== "") {
                                  setSelectedType(
                                    types.find((type) => {
                                      return type._id === e.target.value;
                                    }).name
                                  );
                                } else {
                                  setSelectedType("");
                                }
                              }}
                            >
                              <option value="">Select type</option>
                              {types.map((type) => (
                                <option key={type._id} value={type._id}>
                                  {type.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {selectedType === "USPS Priority" && (
                          <div className="col-12 col-md-6">
                            <div className="form-group mb-3">
                              <label>Template</label>
                              <select
                                onChange={(e) => {
                                  setTemplateType(e.target.value);
                                }}
                                required
                                name="template"
                                className="form-control"
                              >
                                <option value=""> Select type</option>
                                <option value="indica">Indica</option>
                                <option value="stamps">Stamps</option>
                                <option value="evs">EVS</option>
                                <option value="normal">Normal</option>
                              </select>
                            </div>
                          </div>
                        )}

                        <div className="col">
                          <div className="form-group mb-3">
                            <label>CSV</label>
                            <input
                              type="file"
                              className="form-control"
                              id="CSV"
                              name="csv"
                              accept=".csv"
                              required
                              onChange={(e) => {
                                setCSV(e.target.files[0]);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-end align-items-center mt-5">
                        <div>
                          {csvpaydisabled ? (
                            <button
                              className="btn btn-gray mr-2"
                              disabled={uspsValidateDisabled}
                              onClick={validateCSV}
                            >
                              Validate CSV {validateLoader}
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn mr-2 btn btn-primary"
                              disabled={csvpaydisabled}
                              onClick={createOrderFromCSV}
                            >
                              Place Order ${csvPrice} {loader}
                            </button>
                          )}

                          <a
                            href="/assets/sample.csv"
                            className="btn  btn-info text-white"
                            download
                          >
                            <em className="icon ni ni-download" />
                            Download Sample CSV
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">CSV Orders</h5>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Order ID</th>
                              <th>Order Date</th>
                              <th>Orders</th>

                              <th>Price</th>
                              {/* <th>Action</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {csvOrders &&
                              csvOrders.map((order, index) => (
                                <tr key={index}>
                                  <td>{order._id}</td>
                                  <td>
                                    {new Date(order.createdAt).toDateString()}
                                  </td>
                                  <td>{order.orders?.length}</td>

                                  <td>${order.total}</td>
                                  <td>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={() => {
                                        downloadZip(order._id);
                                      }}
                                    >
                                      Download
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CsvOrders;
